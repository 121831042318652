import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/postCard"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const ProjectIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  let postCounter = 0

  return (
    <Layout title={siteTitle}>
      <SEO
        description={"Arq. Andrey Jiménez, graduado de la Universidad de Costa Rica en 2020 miembro del Colegio Federado de Ingenieros y Arquitectos de Costa Rica"}
        title="Inicio"
        keywords={['Arquitecto', 'experiencia', 'calidad', 'soluciones', 'Arquitectura', 'Andrey Jimenez', 'Andrey Jiménez', 'Arq. Andrey Jiménez', 'Costa Rica', 'Alajuela', 'San José', 'UCR', 'Universidad de Costa Rica', 'SJO', 'Puntares', 'Cartago', 'Limon', 'Guanascate', 'San Ramon', 'San Ramón', 'Diseño', 'Interiores']}
      />
      {/* <Bio /> */}
        <header className="page-head">
          <h1 className="page-head-title">
            DISEÑO + CONSTRUCCIÓN
          </h1>
        </header>
      <div className="post-feed">
        {posts.map(({ node }) => {
          postCounter++
          return (
            <PostCard
              key={node.fields.slug}
              count={postCounter}
              node={node}
              postClass={`post`}
            />
          )
        })}
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            # date (formatString: "MMMM DD, YYYY")
            date
            title
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ProjectIndex location={props.location} props data={data} {...props} />
    )}
  />
)
